<template>
  <category>
    <template #hero>
      <category-hero :background-texture="cover">
        Software <br />
        Development
      </category-hero>
    </template>
    <template #intro>
      <div class="category__intro">
        <div class="container">
          <div class="columns">
            <div class="column is-2 is-12-small">
              <div class="s-lateral-heading">About</div>
              <div class="divider--wide h-m-t-10" />
            </div>
            <div class="column is-8 is-12-small">
              <p class="text -color-black">
                By combining deep software development expertise, with a
                relentless focus on planning and execution processes, we are
                able to develop unique products quickly, delivering reliable
                product experiences.
              </p>
              <p class="text -color-black">
                Good software developers are problem solvers with deep technical
                knowledge. A flexible mindset and focus on business goals are
                the main success factors when it comes to building successful
                applications.
              </p>
              <p class="text -color-black">
                With us, your product will be engineered on a solid foundation,
                that helps you scale while keeping your application agile and
                your costs in check.
              </p>
              <p
                class="text -color-black -font-weight-700 -size-large h-case-uppercase"
              >
                Our end goal is to deliver work we're proud of.
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #services>
      <category-services :services="services" />
    </template>
    <template #articles>
      v-if="articles.length > 0">
      <section class="category__articles">
        <div class="container">
          <div class="columns">
            <div class="column is-2 is-12-small">
              <div class="s-lateral-heading">Articles</div>
              <div class="divider--wide h-m-t-10" />
            </div>
            <div class="column is-10 is-12-small">
              <articles :articles="articles" />
            </div>
          </div>
        </div>
      </section>
    </template>
    <template #callToAction>
      <call-to-action
        :cover="cta.cover"
        :title="cta.title"
        :content="cta.content"
        :button="cta.button"
      />
    </template>
  </category>
</template>

<script>
import Category from '~/components/Category.vue'
import CategoryHero from '~/components/CategoryHero.vue'
import CategoryServices from '~/components/CategoryServices.vue'
import Articles from '~/components/Articles.vue'
import CallToAction from '~/components/CallToAction.vue'
import { mapMutations } from 'vuex'

export default {
  meta: {
    title:
      'Software Architects using PHP, JavaScript, Ruby and Elixir in Romania',
    keywords:
      'Laravel, PHP Development, Elixir Development, Phoenix Development, Amazon Web Services, AWS, PHP, JavaScript, Node.js',
    indexed: true,
    category: true,
  },
  components: {
    category: Category,
    'category-hero': CategoryHero,
    'category-services': CategoryServices,
    'call-to-action': CallToAction,
    articles: Articles,
  },
  layout: 'blog',
  data() {
    return {
      articles: [],
      title: 'Software Architects using PHP, JavaScript, Ruby and Elixir',
      keywords:
        'Laravel, PHP Development, Elixir Development, Phoenix Development, Amazon Web Services, AWS, PHP, JavaScript, Node.js',
      description:
        "We're Software Architects versed in PHP, JavaScript, Ruby and Elixir. Let's talk about your project!",
      cover: 'covers/web-development',
      social: 'covers/web-development',
      cta: {
        title: 'Create products that <strong>behave flawlessly</strong>',
        content: 'With an excellent performance under pressure.',
        cover: require('~/assets/images/covers/call-to-action-web-development.jpg'),
        button: {
          url: '/#contact',
          title: "Let's talk",
        },
      },
      services: [
        {
          name: 'PHP Developers',
        },
        {
          name: 'Elixir Developers',
        },
        {
          name: 'Laravel Development',
        },
        {
          name: 'Phoenix Development',
        },
        {
          name: 'Node.js',
        },
        {
          name: 'Restful APIs',
        },
        {
          name: 'Amazon Web Services',
        },
        {
          name: 'Wordpress Development',
        },
        {
          name: 'Database Programming',
        },
      ],
    }
  },
  head: function () {
    return {
      title: this.title,

      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.description,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.keywords,
        },

        {
          hid: 'og:title',
          property: 'og:title',
          content: this.title,
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.description,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content:
            'https://graffino.com/' +
            require(`~/assets/images/${this.social}.jpg`),
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: `https://graffino.com${this.$route.path}`,
        },
        {
          hid: 'twitter:title',
          property: 'twitter:title',
          content: this.title,
        },
        {
          hid: 'twitter:description',
          property: 'twitter:description',
          content: this.description,
        },
        {
          hid: 'twitter:image',
          property: 'twitter:image',
          content:
            'https://graffino.com/' +
            require(`~/assets/images/${this.social}.jpg`),
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: `https://graffino.com${this.$route.path}`,
        },
      ],
    }
  },
  created() {
    const currentRouteName = this.$router.history.current.name
    const articles = []
    this.$router.options.routes
      .filter(
        (route) =>
          route.name.includes(currentRouteName) &&
          route.name !== currentRouteName,
      )
      .map((article) => {
        article.component().then((component) => {
          if (component.meta && component.meta.indexed === true) {
            articles.push({
              path: article.path,
              component,
            })
          }
        })
      })
    this.$set(this, 'articles', articles)
  },
  mounted() {
    this.closeCategories()
  },
  methods: {
    ...mapMutations({
      closeCategories: 'overlay/closeCategories',
    }),
  },
}
</script>
